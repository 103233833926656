import { useState, useEffect, useContext } from "react";
import "../../../modules/fingerprint-steps/fingerprint-narrative/CraftNarrative.scss";
import {
  changeLicenseStatus,
  getNarrativeData,
  getUserProfile,
  saveRankedValuesArray,
} from "../../../services/user.service";
import { useParams } from "react-router";
import { FingerPrint } from "../../../components/Fingerprint/FingerPrint";
import SessionService from "../../../services/session.service";
import { ISessionUserDto } from "../../../dtos/session-user.dto";
import { useValueOrder, useValuePhilosophy } from "../Survey/Survey.hooks";
import { FingerprintContext } from "../Fingerprint.context";
import { getValueDrivers } from "../Survey/Survey.fn";
import { useSaveFingerprintData } from "../FingerPrint.hooks";

export const FingerPrintResult = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [narrativeData, setNarrativeData] = useState<any>({});
  const [userData, setUserData] = useState<ISessionUserDto | null>(null);

  const params = useParams<{ licenseCode?: string }>();

  const { saveFingerprintData } = useSaveFingerprintData();

  const { user } = SessionService.getInstance();

  const {
    surveyData,
    surveyResults,
    surveyResultsFinal,
    valuePhilosophy,
    selectedValuesByUser,
  } = useContext(FingerprintContext);

  const { getValueOrder, isLoading: isLoadingValueOrder } = useValueOrder(
    user?.id || 0,
    selectedValuesByUser,
    surveyData,
    true
  );

  const { getValuePhilosophy, isLoading: isLoadingValuePhilosophy } =
    useValuePhilosophy(user?.id || 0);

  const loadValues = async () => {
    try {
      const narrativeData = await getNarrativeData(params?.licenseCode || "");

      // Reload browser window case
      if (narrativeData?.rankedValues && !selectedValuesByUser.length) {
        setIsLoading(false);

        setNarrativeData(narrativeData);

        return;
      }

      changeLicenseStatus("completed");

      // Survey session was not found
      if (Object.keys(surveyResults).length === 0) {
        setIsLoading(false);

        return;
      }

      const dataToSave = {
        userId: user?.id,
        licenseCode: params?.licenseCode,
        values: selectedValuesByUser,
        archetype: surveyResults?.archetype,
        archetypeExplanation: surveyResults?.archetype_explanation,
        triggers: surveyResults?.triggers,
        triggersExplanation: Object.entries(
          surveyResults?.triggers_explanation
        )?.map((e) => `${e[0]}: ${e[1]}`),
      };

      // Save survey data already available at this point
      saveRankedValuesArray(dataToSave);

      // Fetch value order
      const { value_drivers, value_drivers_explanation } =
        await getValueOrder();

      // Value order failed
      if (!value_drivers || !value_drivers_explanation) {
        setIsLoading(false);

        return;
      }

      // Fetch value philosophy
      const { values_philosophy: valuesPhilosophy } = await getValuePhilosophy(
        value_drivers,
        value_drivers_explanation
      );

      // Value Philosophy failed
      if (!valuesPhilosophy) {
        setIsLoading(false);

        return;
      }

      // Save value philosophy to db
      saveRankedValuesArray({
        ...dataToSave,
        valuesPhilosophy,
      });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const loadUserProfile = async () => {
    if (!narrativeData?.userId) {
      return;
    }

    try {
      const userData = await getUserProfile(parseInt(narrativeData?.userId));

      setUserData(userData);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    saveFingerprintData();

    loadValues();
  }, []);

  useEffect(() => {
    if (narrativeData?.userId) {
      loadUserProfile();
    }
  }, [narrativeData?.userId]);

  const valueDrivers = getValueDrivers(surveyResultsFinal);

  return (
    <FingerPrint
      isLoading={isLoading || isLoadingValueOrder || isLoadingValuePhilosophy}
      userName={`${userData?.firstName || ""} ${userData?.lastName || ""}`}
      licenseCode={params?.licenseCode || ""}
      valueDrivers={narrativeData?.rankedValues?.split(",") || valueDrivers}
      valuePhilosophy={narrativeData?.valuesPhilosophy || valuePhilosophy}
      archetype={narrativeData?.archetype || surveyResults?.archetype}
      archetypeExplanation={
        narrativeData?.archetypeExplanation ||
        surveyResults?.archetype_explanation
      }
      triggers={narrativeData?.triggers?.split(",") || surveyResults?.triggers}
      triggersExplanation={
        narrativeData?.triggersExplanation
          ?.split(";;")
          ?.reduce((acc: any, curr: any) => {
            const explanation = curr?.split(":");

            return {
              ...acc,
              [explanation[0]]: explanation[1],
            };
          }, {}) || surveyResults?.triggers_explanation
      }
    />
  );
};
