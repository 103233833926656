import { useSelector } from "react-redux";
import { useFormData } from "../../app/hooks";
import SessionService from "../../services/session.service";
import { saveUserFingerprintData } from "../../services/user.service";
import { RootState } from "../../app/store";
import { useCallback } from "react";
import { useHistory } from "react-router";
import API from "../../API";

export function useSaveFingerprintData() {
  const { convertDataToString } = useFormData();

  const routeSelector = useSelector((state: RootState) => {
    return state.appRoute;
  });

  const data = convertDataToString();

  const sessionInstance = SessionService.getInstance();

  const { location } = useHistory();

  const saveFingerprintData = useCallback(async () => {
    if (data != "") {
      saveUserFingerprintData(
        data,
        sessionInstance.licenseCode || "",
        location.pathname,
        routeSelector.visitedRoutes // most likely not needed
      );
    }
  }, [data, routeSelector, sessionInstance]);

  return { saveFingerprintData };
}

export function useGetFingerprintData() {
  const { user, licenseCode } = SessionService.getInstance();

  const getFingerprintData = () =>
    API.get(`fingerprintData/data/${user?.id}/${licenseCode}`);

  return { getFingerprintData };
}
