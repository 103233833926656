export function getValueDrivers(surveyResults: any) {
  const core = surveyResults?.value_drivers?.core_value_drivers;
  const vehicle = surveyResults?.value_drivers?.vehicle_value_drivers;
  const outcome = surveyResults?.value_drivers?.outcome_value_drivers;

  return [...(core || []), ...(vehicle || []), ...(outcome || [])];
}

export function getValueDriverExplanation(valueDriverExplanation: {
  string: string;
}) {
  if (!valueDriverExplanation) {
    return [];
  }

  return Object.entries(valueDriverExplanation)?.map(([key, value]) => [
    key,
    value,
  ]);
}

/**
 * Get additional questions requested for Continue where you left
 * @param currentAmount Questions returned from db
 * @param basicAmount Full survey questions
 * @param additionalAmount Additional questions increment
 */
export function getAdditionalQuestionsRequested(
  currentAmount: number,
  basicAmount: number,
  additionalAmount: number
) {
  const diff = currentAmount - basicAmount;

  if (diff >= 0) {
    return (Math.floor(diff / additionalAmount) + 1) * additionalAmount;
  }

  return 0;
}
