import "./ValuesOrder.scss";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";
import { allActions } from "../../../../app/store";
import { FingerPrintView } from "../../../../features/views/FingerPrintView/FingerPrintView";
import { AudioGuide } from "../../../../modules/fingerprint-steps/fingerprint-narrative";
import { SingleOrderableValue } from "./SingleOrderableValue/SingleOrderableValue";
import { ArrowIcon } from "../../../../features/icons/Icons";
import { FingerprintContext } from "../../Fingerprint.context";
import { AdditionalQuestions } from "../../../../components/AdditionalQuestions/AdditionalQuestions";
import {
  getNarrativeData,
  saveRankedValuesArray,
} from "../../../../services/user.service";
import SessionService from "../../../../services/session.service";
import { useSaveFingerprintData } from "../../FingerPrint.hooks";

export function ValuesOrder() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { saveFingerprintData } = useSaveFingerprintData();

  const { licenseCode, user } = SessionService.getInstance();

  const [isLoadingFromDatabase, setIsLoadingFromDatabase] = useState(false);

  const {
    surveyData,
    selectedValuesByUser,
    surveyResults,
    setSelectedValuesByUser,
    setSurveyResults,
    setSurveyData,
  } = useContext(FingerprintContext);

  useEffect(() => {
    saveFingerprintData();
  }, []);

  const loadValues = async () => {
    try {
      setIsLoadingFromDatabase(true);

      // Save data to database
      if (selectedValuesByUser.length && surveyData.length) {
        // const surveyResults = await getSurveyResults();

        const dataToSave = {
          userId: user?.id,
          licenseCode,
          values: selectedValuesByUser,
          // valuesExplanation: Object.entries(
          //   surveyResults?.value_drivers_explanation
          // )?.map((e) => `${e[0]}: ${e[1]}`),
          archetype: surveyResults?.archetype,
          archetypeExplanation: surveyResults?.archetype_explanation,
          triggers: surveyResults?.triggers,
          triggersExplanation: Object.entries(
            surveyResults?.triggers_explanation
          )?.map((e) => `${e[0]}: ${e[1]}`),
        };

        // Save survey data already available at this point
        saveRankedValuesArray(dataToSave);
      }

      const narrativeData = await getNarrativeData(licenseCode || "");

      // Continue with the database values
      if (narrativeData?.rankedValues && !selectedValuesByUser.length) {
        setSelectedValuesByUser(narrativeData?.rankedValues?.split(","));

        setSurveyResults({
          archetype: narrativeData?.archetype,
          archetype_explanation: narrativeData?.archetypeExplanation,
          triggers: narrativeData?.triggers?.split(","),
          triggers_explanation: narrativeData?.triggersExplanation
            ?.split(";;")
            ?.reduce((acc: any, curr: any) => {
              const explanation = curr?.split(":");

              return {
                ...acc,
                [explanation[0]]: explanation[1],
              };
            }, {}),
          value_drivers: narrativeData?.rankedValues?.split(","),
        });

        setSurveyData(narrativeData?.surveyData);

        setIsLoadingFromDatabase(false);

        return;
      }

      setIsLoadingFromDatabase(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadValues();
  }, []);

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "calculating-values",
        "value-order",
        "building-fingerprint"
      )
    );
  }, []);

  const moveValue = (direction: string, index: number) => {
    let currentValues: Array<string> = [...selectedValuesByUser];
    let placeholder: string = "";
    if (direction === "upward") {
      placeholder = currentValues[index - 1];
      currentValues[index - 1] = currentValues[index];
      currentValues[index] = placeholder;
    } else if (direction === "downward") {
      placeholder = currentValues[index + 1];
      currentValues[index + 1] = currentValues[index];
      currentValues[index] = placeholder;
    }
    setSelectedValuesByUser(currentValues);
  };

  const hasValues = selectedValuesByUser.length > 0;

  return (
    <>
      <FingerPrintView background="darkblue" className="no-shapes">
        {isLoadingFromDatabase && (
          <p style={{ fontSize: "3rem" }}>
            We are now systematizing your Value Drivers...
          </p>
        )}

        {!isLoadingFromDatabase && <AudioGuide />}

        {!isLoadingFromDatabase && (
          <div className="values-order">
            <div className="valus-order-intro">
              <h1>Your Leadership Fingerprint™</h1>
              <p>
                We've analyzed your answers and developed a draft of your
                personalized Leadership Fingerprint™. Please take a moment to
                review each value-driver below and ensure they are in the proper
                order.
              </p>
            </div>

            <div className="values-order-list">
              <ul>
                {selectedValuesByUser.map((v, i) => {
                  return (
                    <SingleOrderableValue
                      key={i}
                      valueName={v}
                      number={i}
                      previous={selectedValuesByUser[i - 1]}
                      isLast={i === selectedValuesByUser.length - 1}
                      move={moveValue}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        )}

        {!hasValues && !isLoadingFromDatabase && (
          <>
            <div>No active survey session is found.</div>
            <div>
              <button
                className="btn btn-primary white"
                onClick={() => {
                  history.push("/digital-fingerprint/earliest-memory");
                }}
              >
                Start new survey
              </button>
            </div>
          </>
        )}

        {!isLoadingFromDatabase && hasValues && (
          <section className="value-order-accuracy">
            <h3>Do your values feel accurate?</h3>

            <div style={{ marginTop: 30, fontSize: "1.5rem" }}>
              Please, let me rethink and{" "}
              <span
                className="back-button"
                onClick={() => {
                  history.push("/digital-fingerprint/add-values");
                }}
              >
                edit my values
              </span>
            </div>

            <div className="button-row">
              <button
                onClick={() => {
                  history.push("/digital-fingerprint/confirm-fingerprint");
                }}
              >
                Yes, create my personal fingerprint <ArrowIcon />
              </button>

              <AdditionalQuestions />
            </div>

            <span className="final-message">
              By clicking “Yes, Create My Personal Fingerprint” I am confirming
              my values and completing my Lapin Leadership Assessment.
            </span>
          </section>
        )}
      </FingerPrintView>
    </>
  );
}
