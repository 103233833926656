import { FC, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { useHistory } from "react-router";
import { FingerprintContext } from "../Fingerprint.context";
import { Loader } from "../../../components/Loader/Loader";
import { saveSureyData } from "../../../services/user.service";
import SessionService from "../../../services/session.service";
import { useSaveFingerprintData } from "../FingerPrint.hooks";

export const Survey: FC = () => {
  const {
    surveyData,
    additionalQuestionsRequested,
    setSurveyResults,
    setSurveyData,
  } = useContext(FingerprintContext);

  const { saveFingerprintData } = useSaveFingerprintData();

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const { user } = SessionService.getInstance();

  useEffect(() => {
    saveFingerprintData();

    submitAndGetNextQuestion(
      user?.id || 0,
      additionalQuestionsRequested,
      surveyData
    );
  }, []);

  const submitAndGetNextQuestion = (
    user_id: number,
    additional_questions_requested: number,
    survey_data: any[]
  ) => {
    setIsLoading(true);

    setSurveyResults({});

    fetch(`${process.env.REACT_APP_AI_BASE_URL}/survey/next-question`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        user_id,
        survey_data,
        additional_questions_requested,
      }),
    })
      .then(
        (res) => {
          res.json().then(({ next_question, is_completed }) => {
            saveSureyData(survey_data);

            if (is_completed) {
              history.push("/digital-fingerprint/potential-values");

              return;
            }

            setSurveyData([...survey_data, { question: next_question }]);
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => setIsLoading(false));
  };

  const goToPrevQuestion = () => {
    const [...surveyDataCopy] = surveyData;

    surveyDataCopy.splice(-1);

    setSurveyData(surveyDataCopy);
  };

  const question = surveyData?.[surveyData.length - 1]?.question;
  const answer = surveyData?.[surveyData.length - 1]?.answer;

  const showPrevQuestionButton = surveyData.length > 1;

  return (
    <FingerPrintView background="darkblue">
      <div className="fingerprint-form">
        <div className="loader">{isLoading && <Loader />}</div>

        <h2>{question || "..."}</h2>

        <div className="textarea-wrapper">
          <textarea
            disabled={isLoading}
            value={answer || ""}
            placeholder="Type here..."
            onChange={(e) => {
              const [...surveyDataCopy] = surveyData;

              // Set current answer
              if (surveyDataCopy.length) {
                surveyDataCopy[surveyDataCopy.length - 1].answer =
                  e.target.value;
                setSurveyData(surveyDataCopy);
              }
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 60,
          }}
        >
          <div>
            {showPrevQuestionButton && (
              <span className="back-button" onClick={goToPrevQuestion}>
                Previous Question
              </span>
            )}
          </div>

          <Button
            disabled={isLoading || !answer?.length}
            style={{ marginTop: 0 }}
            variant="primary white"
            onClick={() => {
              submitAndGetNextQuestion(
                user?.id || 0,
                additionalQuestionsRequested,
                surveyData
              );
            }}
          >
            {isLoading ? "Loading..." : "Submit Answer"}
          </Button>
        </div>

        {/* {!isMeaningfulAnswer && (
          <div style={{ marginTop: 20 }}>
            <span style={{ color: "yellow" }}>&#9888;</span> Please provide a
            detailed and informative response that clearly explains your
            thoughts.
          </div>
        )} */}
      </div>
    </FingerPrintView>
  );
};
