import { FC, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { FingerprintContext } from "../../screens/FingerprintExperience/Fingerprint.context";
import { ADDITIONAL_QUESTION_REQUESTED } from "../../screens/FingerprintExperience/FingerPrint.const";

export const AdditionalQuestions: FC = () => {
  const history = useHistory();

  const { additionalQuestionsRequested, setAdditionalQuestionsRequested } =
    useContext(FingerprintContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div style={{ fontSize: "1.5rem" }}>
        Not quite reflecting your values? Let us ask you
        <span
          className="back-button"
          style={{ marginLeft: 5 }}
          onClick={handleShow}
        >
          a few more questions to better understand.
        </span>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Title>Continue with the additional questions?</Modal.Title>

        <Modal.Footer>
          <span className="back-button" onClick={handleClose}>
            No
          </span>
          <span
            className="back-button"
            onClick={() => {
              setAdditionalQuestionsRequested(
                additionalQuestionsRequested + ADDITIONAL_QUESTION_REQUESTED
              );

              history.push("/digital-fingerprint/earliest-memory");
            }}
          >
            Yes
          </span>
        </Modal.Footer>
      </Modal>
    </>
  );
};
